import { Button, Dialog, List } from "antd-mobile";
import React, { useState, useContext, useEffect } from "react";

import { Link, withRouter } from "react-router-dom";

import PsContext from "../../../context";
import { Col, Row } from "react-bootstrap";
import { siteUrl } from "../../../utils";
import { VENDOR_LOGO } from "../../../utils/data";

const Footer = (props) => {
  const context = useContext(PsContext);

  const policies = [
    { text: "Terms and conditions", path: "/page/terms-and-conditions" },
    { text: "Privacy policy", path: "/page/privacy-policy" },
    { text: "Refund policy", path: "/page/refund-policy" },
    { text: "Cancellation policy", path: "/page/cancellation-policy" },
  ];

  return (
    <>
      <footer className="py-4">
        <div className="container">
          <Row>
            <Col md={2} sm={6} xs={6}>
              <h5 className="fw-bold">Policies</h5>
              <ul className="nav flex-column">
                {policies.map((item) => (
                  <li className="">
                    <Link to={item.path} className="">
                      {item.text}
                    </Link>
                  </li>
                ))}
              </ul>
            </Col>

            <Col md={2} sm={6} xs={6}>
              <h5 className="fw-bold">Quick links</h5>
              <ul className="">
                <li className="">
                  <a href={siteUrl} target="_blank" className="">
                    College website
                  </a>
                </li>
                <li className="">
                  <Link to="" className="">
                    Login
                  </Link>
                </li>
                {context.settingValue("enable_ug_admission") == "1" && (
                  <li className="">
                    <Link
                      to="/instruction/ug-admission-instruction"
                      className=""
                    >
                      UG Registration
                    </Link>
                  </li>
                )}
                {context.settingValue("enable_pg_admission") == "1" && (
                  <li className="">
                    <Link
                      to="/instruction/Pg-admission-instruction"
                      className=""
                    >
                      PG Registration
                    </Link>
                  </li>
                )}
              </ul>
            </Col>
            <Col md={5} className="d-none d-sm-block"></Col>

            <Col md={3}>
              <img
                src="https://res.cloudinary.com/dzyixy4du/image/upload/v1584761826/admision/online_apy_list.png"
                className="w-100"
              />
            </Col>
          </Row>
          <hr />
          <div className=" text-white">
            <Row>
              <Col md={8} className="text-center text-md-start">
                Copyright - {context.settingValue("admission_form_clg_name")}{" "}
                &copy; All rights reserved
              </Col>
              <Col md={4} className="text-center text-md-end">
                Powered by -{" "}
                <a href="https://peacesoft.in" target="_blank">
                  Peace Soft Technologies
                </a>
              </Col>
            </Row>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
