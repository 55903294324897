import React, { useState, useContext, useEffect } from "react";

import { Link, Redirect, withRouter } from "react-router-dom";

import PsContext from "../../context";
import { Card, Col, Form, Row, Button } from "react-bootstrap";
import { LOGIN_FOR } from "../../utils/data";
import axios from "axios";
import { ServiceUrl } from "../../utils/serviceUrl";
import { toast } from "react-hot-toast";
import { Spin } from "antd";

const LoginForm = (props) => {
  const context = useContext(PsContext);
  //console.log(context.settings);
  const [loader, setLoader] = useState(false);
  const [validated, setValidated] = useState(false);
  const [captcha, setCaptcha] = useState([]);
  const [toVerify, setToVerify] = useState(false);

  useEffect(() => {
    loadCaptcha();
  }, []);

  const loadCaptcha = () => {
    setLoader(true);
    axios.get(ServiceUrl.ADMISSION.CAPTCHA).then((res) => {
      if (res["data"].status == "1") {
        setCaptcha(res["data"]);
      }
      setLoader(false);
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }
    setLoader(true);
    axios
      .post(ServiceUrl.ADMISSION.LOGIN, new FormData(form))
      .then((res) => {
        if (res["data"].status == "1") {
          axios.defaults.headers.common["Api-Token"] = res["data"].api;
          context.saveLogin(res["data"].user, res["data"].api, "", "");
          context.updateLogged();
          window.location.href = "";
        } else if (res["data"].status == "2") {
          setToVerify(res["data"].token);
        } else {
          toast.error(res["data"].message || "Error");
          loadCaptcha();
        }
        setLoader(false);
      })
      .catch((er) => {
        setLoader(false);
      });
  };

  if (toVerify)
    return <Redirect to={`/mobile-verification?token=${toVerify}`} />;

  return (
    <>
      <Card className="rounded card-border-btm">
        <Card.Body>
          <div className="pb-1">
            <h5 className="text-center mt-2 mb-2">Sign In to your account</h5>
            <Spin spinning={loader}>
              <Form
                action=""
                method="post"
                noValidate
                validated={validated}
                onSubmit={handleFormSubmit}
              >
                <input
                  type="hidden"
                  name="i__captchaCode"
                  value={captcha.code}
                />
                <Row>
                  <Col md={12}>
                    <Form.Control
                      as="select"
                      className="fw-bold form-select "
                      name="i__loginFor"
                      required
                    >
                      {LOGIN_FOR.map(
                        (item) =>
                          context.settingValue(item.key) == "1" && (
                            <option value={item.value}>{item.text}</option>
                          )
                      )}
                    </Form.Control>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={12}>
                    <label>
                      Registered candidate mobile number (or) mail id
                      <span className="text-danger">*</span>
                    </label>
                    <Form.Control
                      type="text"
                      name="i__userName"
                      className="fw-bold"
                      required
                      placeholder="Mobile number (or) Mail id"
                    />
                    <Form.Control.Feedback type="invalid">
                      Username is required.
                    </Form.Control.Feedback>
                  </Col>
                </Row>
                {/*<Row className="mt-3">
                  <Col md={12}>
                    <Form.Control
                      type="password"
                      name="i__password"
                      className="fw-bold"
                      required
                      placeholder="Password"
                    />
                    <Form.Control.Feedback type="invalid">
                      Password is required.
                    </Form.Control.Feedback>
                  </Col>
                          </Row>*/}
                <Row className="mt-3">
                  <label>
                    Candidate date of birth{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <Col md={12}>
                    <Form.Control
                      type="date"
                      name="i__dob"
                      className="fw-bold"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Candidate date of birth is required.
                    </Form.Control.Feedback>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={5} xs={12} sm={12}>
                    <img src={captcha && captcha.img} />
                    <Button
                      type="button"
                      className="ms-2"
                      variant="white border"
                      onClick={(e) => loadCaptcha()}
                    >
                      <i className="fa-solid fa-arrow-rotate-right"></i>
                    </Button>
                  </Col>
                  <Col md={7} xs={12} sm={12}>
                    <Form.Control
                      type="text"
                      name="i__captcha"
                      required
                      className="fw-bold"
                      placeholder="Enter captcha code"
                    />
                    <Form.Control.Feedback type="invalid">
                      Captcha code is required
                    </Form.Control.Feedback>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md={12} className="d-block d-sm-none" xs={6}>
                    <Link to="/sign-up" className="btn btn-danger w-100">
                      Register
                    </Link>
                  </Col>

                  <Col md={12} xs={6}>
                    <Button type="submit" variant="success" className="w-100">
                      Sign In
                    </Button>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={12} className="text-center ">
                    New Registration? <Link to="/sign-up">Click here</Link>
                  </Col>
                </Row>
              </Form>
            </Spin>
            {/* <Row className="mt-4">
              <Col md={12} className="text-center">
                <Link to="/" className="fs-6">
                  Forgot password ?
                </Link>
              </Col>
                        </Row>*/}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default LoginForm;
