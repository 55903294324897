import React, { useState, useContext, useEffect } from "react";
import $ from "jquery";
import PsContext from "../../../context";
import { Tab, Tabs } from "react-bootstrap";

const TabMenu = (props) => {
  const context = useContext(PsContext);

  const [selectedTab, setSelectedTab] = useState(props.tabKey);

  useEffect(() => {
    setSelectedTab(props.tabkey);
  }, [props.tabKey]);

  const handleTabChange = (key) => {
    setSelectedTab(key);
    if (props.onTabChange) props.onTabChange(key);
  };

  const maxCourseSelection = () =>
    selectedTab == "regular"
      ? context.settingValue("admission_ug_regular_max_course_selection")
      : context.settingValue("admission_ug_self_max_course_selection");

  return (
    <>
      {maxCourseSelection() > 1 && (
        <div
          className="alert alert-danger bg-red-50 fw-bold text-center"
          style={{ fontSize: "16px" }}
        >
          You can select Maximum {maxCourseSelection()} progammes on single
          application.
        </div>
      )}

      <div className="mt-2">
        <Tabs onSelect={handleTabChange} activeKey={selectedTab} fill>
          {context.settingValue("admission_has_ug_regular") == "1" && (
            <Tab
              eventKey={"regular"}
              title="Click here to Apply for Aided Programme"
            />
          )}
          {context.settingValue("admission_has_ug_self") == "1" && (
            <Tab
              eventKey={"self"}
              title="Click here to Apply for Self Financed Programme"
            />
          )}
        </Tabs>
      </div>
    </>
  );
};

export default TabMenu;
