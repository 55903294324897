import React, { useState, useContext, useEffect } from "react";
import $ from "jquery";

import PsContext from "../../../context";
import { Card } from "react-bootstrap";
import { ACADEMIC_YEAR } from "../../../utils";
import axios from "axios";
import { ServiceUrl } from "../../../utils/serviceUrl";

const HelpLineInfo = (props) => {
  const context = useContext(PsContext);

  const [loader, setLoader] = useState(false);
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    loadPage();
  }, []);

  const loadPage = () => {
    setLoader(true);
    axios
      .get(
        ServiceUrl.ADMISSION.CUSTOM_PAGE + "?slug=admission-helpline-numbers"
      )
      .then((res) => {
        if (res["data"].status == "1") {
          setDataList(res["data"].data);
        }
        setLoader(false);
      });
  };

  return (
    <>
      <Card className="rounded card-border-btm">
        <Card.Header className="fw-bold text-center">
          Admission {context.settingValue("current_academic_year")}
        </Card.Header>
        <Card.Body>
          {dataList && dataList.id && (
            <div
              dangerouslySetInnerHTML={{
                __html: dataList.content_html,
              }}
            />
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default HelpLineInfo;
