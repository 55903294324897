import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsInfoCircle } from "react-icons/bs";

const InfoIconWithText = ({ text }) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id="tooltip-top">
          <div style={{ maxWidth: "400px" }}>{text}</div>
        </Tooltip>
      }
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          float: "left",
          marginTop: "5px",
        }}
      >
        <BsInfoCircle style={{ marginRight: "5px" }} />
      </div>
    </OverlayTrigger>
  );
};

export default InfoIconWithText;
