export const ServiceUrl = {
  ADMISSION: {
    SETTINGS: "v1/settings",
    ANNOUNCEMENTS: "v1/admission/announcement/list_announcements",
    DISABILITY_LIST: "v1/admission/login/disability_list",
    CUSTOM_PAGE: "v1/admission/settings/page_slug",
    COMMUNITYLIST: "v1/admission/settings/community_list",
    CASTELIST: "v1/admission/settings/castelist",
    CAPTCHA: "v1/captcha",
    LOGIN: "v1/admission/login/check_user_login",
    REGISTER: "v1/admission/register/new_registration",
    CHECK_MOBILE_VERIFIED: "v1/admission/register/check_mobile_verified",
    RESEND_OTP: "v1/admission/register/otp_resend",
    VERIFY_MOBILE_OTP: "v1/admission/register/otp_verify",
  },
  UG: {
    APPLICANT_MIN_INFO: "v1/admission/ug/min_info",
    STEP_ONE: "v1/admission/ug/save_personal",
    HSC_SUBJECTS: "v1/admission/ug/list_hsc_subjects",
    STEP_TWO: "v1/admission/ug/upload_hsc_marks",
    UPLOAD_FILE: "v1/admission/ug/file_upload",
    CLOSE_FILE_UPLOAD: "v1/admission/ug/close_upload",
    PERSONAL_MARKS_PREVIEW: "v1/admission/ug/personal_marks_preview",
    COURSE_FOR_ME: "v1/admission/ug/course_suggestion",
    COURSE_APPLY: "v1/admission/ug/ug_course_apply",

    VIEW_CERTIFICATE: "v1/admission/ug/view_ug_certificates",
    APPLIED_COURSES: "v1/admission/ug/applied_courses",

    UPDATE_STEP_ONE: "v1/admission/ug/update_personal",
    UPDATE_STEP_TWO: "v1/admission/ug/update_hsc_marks",
    UPDATE_DECLARATION: "v1/admission/ug/update_application_declaration",

    APPLIED_ORDER_DETAILS: "v1/admission/ug/order_details",
    REPAY_LINK: "v1/admission/ug/re_payment_link",
    TRACK_MY_ORDER: "v1/admission/ug/track_my_order_status",

    APPLICATION_DOWNLOAD_LINK: "v1/admission/ug/ugpdf/",
  },
  PG: {
    APPLICANT_MIN_INFO: "v1/admission/pg/min_info",
    STEP_ONE: "v1/admission/pg/save_personal",
    PERSONAL_MARKS_PREVIEW: "v1/admission/pg/personal_marks_preview",
    UG_FOR_PG: "v1/admission/pg/list_ug_majors",
    STEP_TWO: "v1/admission/pg/upload_ug_marks",
    UPLOAD_FILE: "v1/admission/pg/file_upload",
    CLOSE_FILE_UPLOAD: "v1/admission/pg/close_upload",
    COURSE_FOR_ME: "v1/admission/pg/course_suggestion",
    COURSE_APPLY: "v1/admission/pg/pg_course_apply",

    VIEW_CERTIFICATE: "v1/admission/pg/view_pg_certificates",
    APPLIED_COURSES: "v1/admission/pg/applied_courses",

    UPDATE_STEP_ONE: "v1/admission/pg/update_personal",
    UPDATE_STEP_TWO: "v1/admission/pg/update_ug_marks",
    TRACK_MY_ORDER: "v1/admission/pg/track_my_order_status",
    UPDATE_DECLARATION: "v1/admission/pg/update_application_declaration",
    APPLIED_ORDER_DETAILS: "v1/admission/pg/order_details",
    REPAY_LINK: "v1/admission/pg/re_payment_link",
    APPLICATION_DOWNLOAD_LINK: "v1/admission/pg/pgpdf/",
  },
  MPHIL: {
    APPLICANT_MIN_INFO: "v1/mphilapplicant/min_info",
    STEP_ONE: "v1/mphil/register/step_one",
    PERSONAL_MARKS_PREVIEW: "v1/mphil/personal_marks_preview",
    UG_FOR_PG: "v1/mphil/get_ug_forpg",
    STEP_TWO: "v1/mphil/register/ugmarksupload",
    UPLOAD_FILE: "v1/mphil/file_upload",
    CLOSE_FILE_UPLOAD: "v1/mphil/close_upload",
    COURSE_FOR_ME: "v1/mphil/apply/course_for_me",
    COURSE_APPLY: "v1/mphil/course/apply",

    VIEW_CERTIFICATE: "v1/mphil/application/certificate",
    APPLIED_COURSES: "v1/mphil/courses",

    UPDATE_STEP_ONE: "v1/mphil/update/personal",
    UPDATE_STEP_TWO: "v1/mphil/update/marks",
    TRACK_MY_ORDER: "v1/mphil/trackmyorder",

    APPLIED_ORDER_DETAILS: "v1/mphil/orders",
    REPAY_LINK: "v1/mphil/course/repay",
    APPLICATION_DOWNLOAD_LINK: "v1/mphil/pdf/",
  },
};
