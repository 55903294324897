import React, { useState, useContext, useEffect } from "react";

import { Link, withRouter } from "react-router-dom";

import PsContext from "../../context";
import { Card, Col, Form, Row, Button } from "react-bootstrap";
import { ACADEMIC_YEAR } from "../../utils";

const SignUpLink = (props) => {
  const context = useContext(PsContext);

  return (
    <>
      <Card className="rounded shadow card-border-btm">
        <Card.Header className="fw-bold text-center">
          Online admission - {context.settingValue("current_academic_year")}
        </Card.Header>
        <Card.Body>
          <ul className="list-group list-group-flush fs-6">
            {context.settingValue("enable_ug_admission") == "1" && (
              <li className="list-group-item my-2">
                <Link
                  to="/instruction/ug-admission-instruction"
                  className="text-dark fw-bold"
                >
                  Apply for UG
                  <i className="fa-solid fa-chevron-right float-end"></i>
                </Link>
              </li>
            )}
            {context.settingValue("enable_pg_admission") == "1" && (
              <li className="list-group-item my-2">
                <Link
                  to="/instruction/pg-admission-instruction"
                  className="text-dark fw-bold"
                >
                  Apply for PG
                  <i className="fa-solid fa-chevron-right float-end"></i>
                </Link>
              </li>
            )}
            {/* {context.settingValue("enable_mphil_admission") == "1" && (
              <li className="list-group-item my-2">
                <Link
                  to="/instruction/mphil-admission-instruction"
                  className="text-dark fw-bold"
                >
                  Apply for MPhil
                  <i className="fa-solid fa-chevron-right float-end"></i>
                </Link>
              </li>
            )} */}
            <li className="list-group-item my-2">
              <Link to="/page/prospectus" className="text-dark fw-bold">
                Prospectus
                <i className="fa-solid fa-chevron-right float-end"></i>
              </Link>
            </li>
          </ul>
        </Card.Body>
      </Card>
    </>
  );
};

export default SignUpLink;
