import React, { useState, useContext, useEffect } from "react";
import $ from "jquery";
import PsContext from "../../../context";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import StepMenu from "../stepMenu";
import axios from "axios";
import { ServiceUrl } from "../../../utils/serviceUrl";

import { toast } from "react-hot-toast";
import { Spin, Select } from "antd";

import ConfirmModal from "../../confirmModal";

import ProfilePhotoUpload from "./uploads/profilePhotoUpload";
import CertificateUploading from "./uploads/certificateUploading";
import { DENOMINATION, upperCase } from "../../../utils";
import { Link } from "react-router-dom";

const { Option } = Select;

const StepThree = (props) => {
  const context = useContext(PsContext);
  const [loader, setLoader] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);
  const [validated, setValidated] = useState(false);

  const [captcha, setCaptcha] = useState([]);
  const [applicant, setApplicant] = useState([]);

  const [showConfirm, setShowConfirm] = useState(false);
  const [errorMsg, setErrorMsg] = useState([]);

  useEffect(() => {
    loadApplicantInfo();
  }, []);

  const getApplicant = (fieldName) => {
    try {
      var d = applicant;
      return d[fieldName];
    } catch (e) {
      return null;
    }
  };

  const loadApplicantInfo = () => {
    try {
      setLoader(true);
      setApplicant([]);
      var form = new FormData();
      form.append("i__apiCode", context.api);
      axios.post(ServiceUrl.UG.APPLICANT_MIN_INFO, form).then((res) => {
        if (res["data"].status == "1") {
          let d = res["data"].data;

          setApplicant(d);
          setLoader(false);
        } else {
          toast.error(res["data"].message || "error");
          setLoader(false);
        }
      });
    } catch (error) {
      setLoader(false);
    }
  };

  const handleFormSubmit = (e) => {
    setShowConfirm(true);
  };

  const submitForm = () => {
    setLoader(true);
    setShowConfirm(false);
    const form = new FormData();
    form.append("i__apiCode", context.user.api_code);
    form.append("i__userId", context.user.user_id);
    form.append("i__userCode", context.user.user_code);
    axios.post(ServiceUrl.UG.CLOSE_FILE_UPLOAD, form).then((res) => {
      if (res["data"].status == "1") {
        toast.success(res["data"].message || "Success");

        context.updateUser(res["data"].user);
        context.setLoader(false);
        setLoader(false);
        setIsRedirect(true);
        if (props.pageType && props.pageType == "edit") props.history.push("/");
      } else {
        if (res["data"].data) setErrorMsg(res["data"].data);
        else setErrorMsg([]);

        context.setLoader(false);
        setLoader(false);
      }
    });
  };

  const loadCaptcha = () => {
    try {
      setLoader(true);
      axios.get(ServiceUrl.ADMISSION.CAPTCHA).then((res) => {
        setCaptcha(res["data"]);
        setLoader(false);
      });
    } catch (error) {
      setLoader(false);
    }
  };

  const getErrorData = () => {
    let rv = [];
    if (errorMsg && errorMsg.length > 0) {
      errorMsg.map((item, i) => {
        rv.push(<li className="text-danger">{item}</li>);
      });
    }
    return rv;
  };

  return (
    <>
      <div className="container mb-3 ">
        {applicant && applicant.board && (
          <>
            <Row>
              <Col md={{ span: 10, offset: 1 }}>
                <StepMenu activeKey="step3" pageType={props.pageType} />
                <Card>
                  <Card.Header>
                    <div className="float-start">
                      Name :{"  "}
                      <b className="text-uppercase">
                        {applicant.name} {applicant.initial}
                      </b>
                    </div>
                    <div className="float-end">
                      Registration No : {"  "}
                      <b>{applicant.user_code}</b>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Spin spinning={loader}>
                      <input
                        type="hidden"
                        name="i__userId"
                        value={context.user.user_id}
                      />
                      <input
                        type="hidden"
                        name="i__userCode"
                        value={context.user.user_code}
                      />
                      <input
                        type="hidden"
                        name="i__apiCode"
                        value={context.user.api_code}
                      />

                      <Row className="context_box_title ">
                        <Col md={12}>
                          <h5>Profile Photo & Signature </h5>
                        </Col>
                      </Row>

                      <ProfilePhotoUpload
                        applicant={applicant}
                        fileName="profile_photo"
                        filePath={applicant.profile_photo}
                        title="Upload your recent passport size photo"
                        minSize={50000}
                        maxSize={300000}
                        content={
                          <span>
                            Photo should be in .png or .jpg format only. <br />
                            File size : 50 kb - 300 kb
                            <br />
                            Photo with white background only allowed
                            <br />
                            No selfie allowed
                          </span>
                        }
                        onSuccess={(e) => loadApplicantInfo()}
                      />
                      <ProfilePhotoUpload
                        applicant={applicant}
                        fileName="signature"
                        filePath={applicant.signature}
                        title="Upload Your Signature"
                        minSize={30000}
                        maxSize={100000}
                        content={
                          <span>
                            signature should be in .png or .jpg format only.
                            <br />
                            File size : 30 kb - 100 kb
                          </span>
                        }
                        onSuccess={(e) => loadApplicantInfo()}
                      />

                      <Row className="context_box_title mt-2">
                        <Col md={12}>
                          <h5>Other Certificates </h5>
                        </Col>
                      </Row>

                      {upperCase(getApplicant("community")) != "OC" &&
                        context.settingValue(
                          "admission_ug_cert_commu_require"
                        ) == "1" && (
                          <CertificateUploading
                            applicant={applicant}
                            fileName="community_certificate_path"
                            filePath={applicant.community_certificate_path}
                            title="Community Certificate"
                            minSize={80000}
                            maxSize={300000}
                            content={
                              <span>
                                File should be in .png or .jpg or .pdf format
                                only.
                                <br />
                                File size : 80 kb - 300 kb
                              </span>
                            }
                            onSuccess={(e) => loadApplicantInfo()}
                          />
                        )}

                      {/* HSC Marksheet */}
                      {context.settingValue(
                        "admission_ug_cert_marksheet_require"
                      ) == "1" && (
                        <CertificateUploading
                          applicant={applicant}
                          fileName="mark_sheet_path"
                          filePath={applicant.mark_sheet_path}
                          title="+2 Marksheet "
                          minSize={80000}
                          maxSize={300000}
                          content={
                            <span>
                              File should be in .png or .jpg or .pdf format
                              only.
                              <br />
                              Upload HSC Marksheet OR Result Copy
                              <br />
                              File size : 80 kb - 300 kb
                            </span>
                          }
                          onSuccess={(e) => loadApplicantInfo()}
                        />
                      )}

                      {/* School Transfer certificate*/}
                      {context.settingValue("admission_ug_cert_tc_require") ==
                        "1" && (
                        <CertificateUploading
                          applicant={applicant}
                          fileName="transfer_certificate_path"
                          filePath={applicant.transfer_certificate_path}
                          title="Transfer Certificate "
                          minSize={80000}
                          maxSize={300000}
                          content={
                            <span>
                              File should be in .png or .jpg or .pdf format
                              only.
                              <br />
                              File size : 80 kb - 300 kb
                            </span>
                          }
                          onSuccess={(e) => loadApplicantInfo()}
                        />
                      )}

                      {/* Physical disabbility certificate*/}
                      {getApplicant("physically_challanged") == "1" &&
                        context.settingValue(
                          "admission_ug_cert_physical_require"
                        ) == "1" && (
                          <CertificateUploading
                            applicant={applicant}
                            fileName="physical_certificate_path"
                            filePath={applicant.physical_certificate_path}
                            title="Disability Certificate "
                            minSize={80000}
                            maxSize={300000}
                            content={
                              <span>
                                File should be in .png or .jpg or .pdf format
                                only.
                                <br />
                                File size : 80 kb - 300 kb
                              </span>
                            }
                            onSuccess={(e) => loadApplicantInfo()}
                          />
                        )}

                      {/* Ex-Service man certificate*/}
                      {getApplicant("ex_army_child") == "1" &&
                        context.settingValue(
                          "admission_ug_cert_exservice_require"
                        ) == "1" && (
                          <CertificateUploading
                            applicant={applicant}
                            fileName="exmilitry_certificate_path"
                            filePath={applicant.exmilitry_certificate_path}
                            title="Ex-Service man certificate "
                            minSize={80000}
                            maxSize={300000}
                            content={
                              <span>
                                File should be in .png or .jpg or .pdf format
                                only.
                                <br />
                                File size : 80 kb - 300 kb
                              </span>
                            }
                            onSuccess={(e) => loadApplicantInfo()}
                          />
                        )}

                      {/* Sports certificate*/}
                      {getApplicant("in_sports") == "1" &&
                        context.settingValue(
                          "admission_ug_cert_sports_require"
                        ) == "1" && (
                          <CertificateUploading
                            applicant={applicant}
                            fileName="sports_certificate_path"
                            filePath={applicant.sports_certificate_path}
                            title="Sports certificate "
                            minSize={80000}
                            maxSize={300000}
                            content={
                              <span>
                                File should be in .png or .jpg or .pdf format
                                only.
                                <br />
                                File size : 80 kb - 300 kb
                              </span>
                            }
                            onSuccess={(e) => loadApplicantInfo()}
                          />
                        )}

                      {/* NSS certificate*/}
                      {getApplicant("in_nss") == "1" &&
                        context.settingValue("admission_ug_cert_nss_require") ==
                          "1" && (
                          <CertificateUploading
                            applicant={applicant}
                            fileName="nss_certificate_path"
                            filePath={applicant.nss_certificate_path}
                            title="NSS certificate "
                            minSize={80000}
                            maxSize={300000}
                            content={
                              <span>
                                File should be in .png or .jpg or .pdf format
                                only.
                                <br />
                                File size : 80 kb - 300 kb
                              </span>
                            }
                            onSuccess={(e) => loadApplicantInfo()}
                          />
                        )}

                      {/* NCC certificate*/}
                      {getApplicant("in_ncc") == "1" &&
                        context.settingValue("admission_ug_cert_ncc_require") ==
                          "1" && (
                          <CertificateUploading
                            applicant={applicant}
                            fileName="ncc_certificate_path"
                            filePath={applicant.ncc_certificate_path}
                            title="NCC certificate "
                            minSize={80000}
                            maxSize={300000}
                            content={
                              <span>
                                File should be in .png or .jpg or .pdf format
                                only.
                                <br />
                                File size : 80 kb - 300 kb
                              </span>
                            }
                            onSuccess={(e) => loadApplicantInfo()}
                          />
                        )}

                      {/* BAPTISM CERTIFICATE*/}
                      {upperCase(getApplicant("denomination")) ==
                        upperCase(
                          context.settingValue("clg_admission_denomination")
                        ) &&
                        context.settingValue("ug_baptism_certificate") ==
                          "1" && (
                          <CertificateUploading
                            applicant={applicant}
                            fileName="baptism_certificate_path"
                            filePath={applicant.baptism_certificate_path}
                            title="Baptism certificate "
                            minSize={80000}
                            maxSize={300000}
                            content={
                              <span>
                                Baptism or Certificate from the pastorate
                                <br />
                                File should be in .png or .jpg or .pdf format
                                only.
                                <br />
                                File size : 80 kb - 300 kb
                              </span>
                            }
                            onSuccess={(e) => loadApplicantInfo()}
                          />
                        )}

                      <Row className="my-4">
                        {props.pageType && props.pageType == "edit" && (
                          <Col md={6}>
                            <Link to="/" className="btn btn-danger w-100">
                              Cancel
                            </Link>
                          </Col>
                        )}
                        <Col
                          md={
                            props.pageType && props.pageType == "edit" ? 6 : 12
                          }
                        >
                          <Button
                            type="button"
                            onClick={(e) => setShowConfirm(true)}
                            variant="success"
                            className="w-100"
                            disabled={loader}
                          >
                            Save & Continue
                          </Button>
                        </Col>
                      </Row>
                    </Spin>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>

      <ConfirmModal
        title="Confirm"
        content={
          <div>
            No additional documents will be entertained after submission of
            Application form through online mode.
          </div>
        }
        confirmText="Save & Proceed"
        show={showConfirm}
        onHide={(e) => setShowConfirm(false)}
        onCancel={(e) => setShowConfirm(false)}
        onConfirm={(e) => submitForm()}
      />

      <ConfirmModal
        title="Error"
        content={<div>{getErrorData()}</div>}
        confirmText="Ok"
        show={errorMsg.length > 0}
        onHide={(e) => setErrorMsg([])}
        onCancel={(e) => setErrorMsg([])}
        onConfirm={(e) => setErrorMsg([])}
      />
    </>
  );
};

export default StepThree;
