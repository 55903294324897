import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

import InputField2 from "./inputField2";
import InputField from "./inputField";
import PsContext from "../../../context";
import { PsLabel, upperCase } from "../../../utils";

const SpecialReservation = (props) => {
  const context = useContext(PsContext);
  const [loader, setLoader] = useState(false);

  const [basic, setBaisc] = useState([]);
  const [marks, setMarks] = useState([]);
  const [preference, setPreference] = useState([]);

  const field = (fieldName) => {
    if (props.data && props.data[fieldName]) return props.data[fieldName];
  };

  return (
    <>
      {context.settingValue("ug_donate_blood_input") == "1" && (
        <InputField2
          label="Whether the applicant is differently abled ?"
          value={field("donate_blood") == "1" ? "Yes" : "No"}
        />
      )}

      {context.settingValue("ug_first_graduate_input") == "1" && (
        <InputField2
          label=" Whether the applicant is First-Generation
          Applicant?"
          value={field("first_graduate") == "1" ? "Yes" : "No"}
        />
      )}

      <InputField2
        label="Whether the applicant is willing to donate Blood ?"
        value={field("physically_challanged") == "1" ? "Yes" : "No"}
      />

      {field("physically_challanged") == "1" && (
        <>
          <InputField
            label="Disability Code"
            value={upperCase(field("disability_code"))}
          />
          <InputField
            label="Disability Percentage"
            value={upperCase(field("disability_percentage"))}
          />
          <InputField
            label="District Name"
            value={upperCase(field("disablity_district"))}
          />
          <InputField
            label="Disability Card No"
            value={upperCase(field("disability_card_no"))}
          />
          <hr />
        </>
      )}

      <InputField2
        label="Whether the applicant is child of Ex-Serviceman ?"
        value={field("ex_army_child") == "1" ? "Yes" : "No"}
      />

      <InputField2
        label="Whether the applicant is migrated from Jammu and
		Kashmir ?"
        value={field("citizen_from_jammu") == "1" ? "Yes" : "No"}
      />

      <InputField2
        label="Whether the applicant is Tamil Origin from Andaman
		Nicobar islands ?"
        value={field("srilankan_citizen") == "1" ? "Yes" : "No"}
      />

      <InputField2
        label="Whether the applicant is belongs to Other State or NRI
		?"
        value={field("is_nri") == "1" ? "Yes" : "No"}
      />

      <InputField2
        label="Participation in Sports ?"
        value={field("in_sports") == "1" ? "Yes" : "No"}
      />

      {field("in_sports") == "1" && (
        <>
          <Row className="mt-2">
            <Col md={2}>
              <PsLabel text="Level in Sports" />
            </Col>
            <Col md={4}>
              <Form.Control
                type="text"
                size="sm"
                className="fw-bold text-uppercase
                "
                value={field("sports_level")}
              />
            </Col>
            <Col md={2}>
              <PsLabel text="Name of the Soports" />
            </Col>
            <Col md={4}>
              <Form.Control
                type="text"
                size="sm"
                className="fw-bold text-uppercase
                "
                value={field("sports_name")}
              />
            </Col>
          </Row>
        </>
      )}

      {context.settingValue("ug_yrc_input_field") == "1" && (
        <InputField2
          label="Participation in YRC ?"
          value={field("in_yrc") == "1" ? "Yes" : "No"}
        />
      )}

      <InputField2
        label="Participation in NCC ?"
        value={field("in_ncc") == "1" ? "Yes" : "No"}
      />

      <InputField2
        label="Participation in NSS ?"
        value={field("in_nss") == "1" ? "Yes" : "No"}
      />
    </>
  );
};

export default SpecialReservation;
